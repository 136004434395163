import React from "react"
import logo from "../../content/assets/rappler-plus-dark.png";


class Login extends React.Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: ''
        }
        this.handleTextChange = this.handleTextChange.bind(this);
        this.setStorage = this.setStorage.bind(this);
    }

    checkSession() {
        const sessionKey  = window.sessionStorage.getItem('rapplerPlus');
        const email = window.sessionStorage.getItem("rapplerPlusEmail");
        const password = window.sessionStorage.getItem("rapplerPlusPassword");
    
        if(!sessionKey)
          return;
        
        if(!email)
          return;
    
        if(password !== "r4ppl3rPlu$El3ction")
          return;

        window.location.href = "/paper";
    }

    setStorage() {
        const {password, email} = this.state;
        if(password === "r4ppl3rPlu$El3ction") {
            let timestamp = new Date().getUTCMilliseconds();
            window.sessionStorage.setItem("rapplerPlus", timestamp);
            window.sessionStorage.setItem("rapplerPlusEmail", email);
            window.sessionStorage.setItem("rapplerPlusPassword", password);

            window.location.href = "/paper";
        } else {
            alert("Invalid Credentials");
        }
    }

    handleTextChange(e) {
        // let value = e.
        // this.setState();
        this.setState({
            [e.target.name]: e.target.value
        });

    }


    render() {
        return (
            <div style={{display:"flex", flexDirection:"column", width: "400px", alignItems: "left", margin: "150px auto"}}>
                <img src={logo} style={{maxWidth: "200px", width: "auto", height: "auto"}}></img>
                <label style={{marginBottom: "10px"}}>Plus Exclusive Email: </label>
                <input type="email" style={{borderRadius: "5px", marginBottom: "10px"}} onChange={ e => this.handleTextChange(e)} name="email"/>
                <label style={{marginBottom: "10px"}}>Plus Exclusive Password: </label>
                <input type="password" style={{borderRadius: "5px", marginBottom: "10px"}} onChange={ e => this.handleTextChange(e)} name="password"/>
                <button style={{marginBottom: "10px"}} onClick={this.setStorage}>Enter</button>
            </div>
        );
    }
}

export default Login;
